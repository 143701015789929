<template>
    <modal ref="modalDuplicarHorario" titulo="Duplicar Horario" no-aceptar adicional="Duplicar" @adicional="duplicate">
        <div class="row mx-0 justify-center">
            <ValidationObserver ref="validacion">
                <div class="col-8 text-general f-15 f-400">
                    Selecciona el cedis al cual se duplicará <br />
                    esta configuración
                </div>
                <div class="col-8 mt-2">
                    <small class="text-general pl-3">
                        Seleccionar cedis
                    </small>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Seleccionar cedis">
                        <el-select v-model="idCedisSelect" class="w-100" size="small">
                            <el-option
                            v-for="item in cedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            :disabled="item.id === idCedis"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-8 mt-5">
                    <p class="text-general f-15">Selecciona a quien se le duplicará</p>
                    <div class="row mx-0 mt-3">
                        <el-checkbox v-model="checkCedis" class="check-green">
                            Cedis
                        </el-checkbox>
                        <el-checkbox v-model="checkLeecheros" class="check-green">
                            {{$config.vendedor}}s
                        </el-checkbox>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import Service from '@/services/configurar/admin/cedisHorarios'

export default {
    data(){
        return {
            cedis: [],
            idCedis: null,
            idCedisSelect: '',
            checkCedis: true,
            checkLeecheros: true,
        }
    },
    methods: {
        toggle(idCedis){
            this.idCedis = idCedis;
            this.getCedis();
            this.$refs.modalDuplicarHorario.toggle();
        },

        async getCedis(){
            try {
                const {data} = await Service.getCedis();
                this.cedis = data.cedis;
            } catch(e){
                this.error_catch(e)
            }
        },

        async duplicate(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid || (!this.checkCedis && !this.checkLeecheros))return;
                const model = {
                    idCedis: this.idCedisSelect,
                    idCedisDuplicado: this.idCedis,
                    cedis: this.checkCedis,
                    leecheros: this.checkLeecheros
                };
                const {data} = await Service.postDuplicar(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$refs.modalDuplicarHorario.toggle();
            } catch(e){
                this.error_catch(e)
            }
        }
    }
}
</script>
